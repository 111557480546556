<template>
  <v-container class="mt-0 pt-0">
    <v-card-title>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Pesquisar"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="columns"
      :items="filteredData"
      :search="search"
      locale="pt"
    >
      <template v-slot:item.actions="{item}">
        <v-tooltip center>
          <template v-slot:activator="{ on }">
            <v-icon class="mr-4" small @click="openEmailBox(item)"
            >mdi-email-search-outline</v-icon>
          </template>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog scrollable v-model="dialog" max-width="60%">
      <EmailSyncDetail
        @closeBox="closeBox($event)"
        :emailContent="selectedEmailContent"
        :type="type"
        v-if="showDetail"
      />
    </v-dialog>
  </v-container>
</template>
<script>
import EmailSyncDetail from "./EmailSyncDetail";
export default {
  props: ["emailList", "type"],
  components: {
    EmailSyncDetail
  },
  data: () => ({
    syncIcon: "mdi-link-variant-plus",
    syncedIcon: "mdi-link-variant-remove",
    showIcon: "",
    selectedEmailContent: "",
    showDetail: true,
    dialog: false,
    search: "",
    columns: [
      { text: "Assunto", value: "subject" },
      { text: "E-mail", value: "from" },
      { text: "Data recebido", value: "formatted_date" },
      { text: "", align: "end", value: "actions", sortable: false }
    ]
  }),
  mounted() {
  },
  computed: {
    filteredData() {
      return this.emailList.filter(value => {
        return (this.search || value.subject.toLowerCase().includes(this.search.toLowerCase())) &&
          (this.search || value.from.toLowerCase().includes(this.search.toLowerCase())) &&
          (this.search || value.formatted_date.toLowerCase().includes(this.search.toLowerCase()))
      });
    }
  },
  methods: {
    openEmailBox(email) {
      this.dialog = true;
      this.selectedEmailContent = email;
    },
    closeBox(show) {
      this.dialog = show;
    },
  }
};
</script>
