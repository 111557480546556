<template>
  <v-container class="mt-0 pt-0">
    <v-toolbar flat dense>
      <v-tabs v-model="tab" align-with-title>
        <v-tabs-slider color="secondary"></v-tabs-slider>
        <v-tab
          v-for="tabName in tabNames"
          :key="tabName"
          @change="showIndex(tabName)"
        >
          {{ tabName }}
        </v-tab>
      </v-tabs>
    </v-toolbar>
    <v-skeleton-loader type="table" v-if="loading"></v-skeleton-loader>
    <v-tabs-items v-model="tab" v-if="!loading">
      <v-tab-item v-for="tabOption in tabNames" :key="tabOption">
        <v-alert
          border="left"
          dense
          colored-border
          type="warning"
          v-if="emailList.length < 1"
          class="mt-5"
          >Nenhum e-mail encontrado!
        </v-alert>
        <EmailSyncList
          :emailList="emailList"
          :type="emailType"
          v-if="emailList.length"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";

import EmailSyncList from "./EmailSyncList.vue";

export default {
  components: {
    EmailSyncList
  },
  data() {
    return {
      tabNames: ["Não vinculados", "Já Vinculados"],
      tab: null,
      loading: true,
      emailList: "",
      emailType: "sync"
    };
  },

  async mounted() {
    try {
      await Promise.all([
        this.loadEmailList(),
        this.loadSyncedEmailList()
      ]);
      this.emailList = this.syncEmailList.data;
    } catch (error) {
      await this.$swal("Oops...", error.message, "error");
    }
    this.loading = false;
  },

  computed: {
    ...mapState("emailSync", ["syncEmailList", "syncedEmailList"])
  },

  methods: {
    ...mapActions("emailSync", ["loadEmailList", "loadSyncedEmailList"]),
    showIndex(tabName) {
      if (tabName == "Não vinculados") {
        this.emailList = this.syncEmailList.data;
        this.emailType = "sync";
      } else {
        this.emailList = this.syncedEmailList.data;
        this.emailType = "synced";
      }
    }
  }
};
</script>
